<template>
  <v-container id="op" :key="refresh" fluid>
    <v-card v-if="machine && !planItem" elevation="0">
      <v-card-title>
        <v-toolbar>
          <v-btn icon class="hidden-xs-only" @click="changeMachine">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer />
          <v-icon small left>
            {{ machineIcon(machine) }}
          </v-icon>
          {{ machine.name }}
        </v-toolbar>
      </v-card-title>
    </v-card>

    <v-card v-if="machine && !planItem" class="pa-4" elevation="0">
      <v-row align-self="end">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
          clearable
        />
      </v-row>
      <v-row>
        <v-icon
          medium
          @click="
            () => {
              keybordVisible = !keybordVisible;
            }
          "
        >
          {{ keybordVisible ? 'mdi-minus' : 'mdi-plus' }}
        </v-icon>
        <v-icon
          medium
          @click="
            () => {
              keybordVisible = !keybordVisible;
            }
          "
        >
          mdi-keyboard
        </v-icon>
        <SimpleKeyboard
          v-if="keybordVisible"
          :key="keyboardEpoch"
          keyboard-class="searchplan"
          :input="search"
          :layout="layout"
          @onChange="
            (input) => {
              search = input;
            }
          "
        />
      </v-row>
    </v-card>

    <v-layout v-if="!machine" row wrap>
      <v-col
        v-for="group in groupedMachines"
        :key="group.type"
        cols="auto"
        class="d-flex flex-column align-start"
      >
        <v-card
          v-for="machine in group.machines"
          :key="machine.id"
          color="primary"
          dark
          elevation="0"
          max-width="300"
          min-width="300"
          class="ma-2"
          @click="onMachine(machine)"
        >
          <v-card-title>
            <v-icon large left>
              {{ machineIcon(machine) }}
            </v-icon>
            <span class="text-h3 font-weight-light">{{
              tType(machine.type)
            }}</span>
          </v-card-title>

          <v-card-text class="text-h2 font-weight-bold">
            {{ machine.name }}
          </v-card-text>

          <v-card-actions>
            <v-list-item class="grow">
              <v-row align="center" justify="end">
                <v-chip color="secondary">
                  {{ getMachineCount(machine) }}
                </v-chip>
              </v-row>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-layout>

    <component
      :is="currentForm"
      v-if="machine"
      v-bind="params"
      :machine="machine"
      :epoch="epoch"
      :refresh="refresh"
      :search="search"
    />
  </v-container>
</template>

<script>
import plan from '@/views/dashboard/components/plan/planCard';
import MachineService from '@/services/MachineService.js';
import PlanService from '@/services/PlanService.js';
import operatorForm from '@/views/operator/components/operatorForm.vue';
import machineMixin from '@/views/dashboard/components/machines/machineMixin.js';
import SimpleKeyboard from '@/components/keyboard/SimpleKeyboard';

export default {
  components: { plan, SimpleKeyboard },
  mixins: [machineMixin],
  data: function () {
    return {
      machine: null,
      machines: null,
      epoch: 1,
      params: null,
      counts: [],
      refresh: 0,
      planItem: null,
      search: '',
      keybordVisible: false
    };
  },
  computed: {
    groupedMachines() {
      if (!this.machines) return [];

      const typeOrder = [
        'printer',
        'varnisher',
        'die cutter',
        'braille',
        'gluer',
        'assembler',
        'packager'
      ];

      // First group machines by type
      const groups = this.machines.reduce((groups, machine) => {
        const type = machine.type || 'default';
        if (!groups[type]) {
          groups[type] = [];
        }
        groups[type].push(machine);
        return groups;
      }, {});

      // Sort machines within each group (default first)
      for (const type in groups) {
        groups[type].sort((a, b) => {
          if (a.is_default) return -1;
          if (b.is_default) return 1;
          return a.name.localeCompare(b.name);
        });
      }

      // Convert to ordered array based on typeOrder
      return typeOrder
        .filter((type) => groups[type]) // Only include types that have machines
        .map((type) => ({
          type,
          machines: groups[type]
        }));
    }
  },
  watch: {},
  async mounted() {
    console.log('plans.mounted');
    await this.loadMachines();
    await this.loadMachinesCounts();
    this.$root.$on('operator:plan_item_updated', this.planItemUpdated);
    this.$root.$on('operator:select', this.selectItem);
    this.$root.$on('operator:list', this.backToList);
    this.$root.$on('operator:refresh', this.doRefresh);
  },
  beforeDestroy() {
    this.$root.$off('operator:plan_item_updated', this.planItemUpdated);
    this.$root.$off('operator:select', this.selectItem);
    this.$root.$off('operator:list', this.backToList);
    this.$root.$off('operator:refresh', this.doRefresh);
  },
  methods: {
    async loadMachinesCounts() {
      const response = await Promise.all(
        this.machines.map((p) =>
          PlanService.getMachinePlan(p.id, [], this.search, false)
        )
      );
      this.counts = response.map((rsp) => rsp[1]);
      this.refresh++;
    },
    async loadMachines() {
      this.loading = true;
      try {
        this.machines = await MachineService.getMachines();
        for (const i in this.machines) {
          this.counts[i] = '...';
        }
        console.log('counts set', this.counts);
      } catch (err) {
        this.showError(this, err);
      } finally {
        this.loading = false;
      }
    },
    changeMachine() {
      this.machine = null;
      this.refresh++;
    },
    onMachine(evt) {
      console.log(evt);
      this.machine = evt;
      this.params = { operatorView: true };
      this.currentForm = plan;
    },
    async onRefresh() {
      console.log('Plans.onRefresh()');
      await PlanService.refresh();
      this.epoch++;
    },
    async onReset() {
      console.log('Plans.onReset()');
      await PlanService.reset();
      this.epoch++;
    },
    planItemUpdated(pi) {
      console.log('plan item updated:', pi);
      if (this.planItem?.id === pi.id) {
        this.planItem = pi;
        this.params['planItem'] = this.planItem;
      }
    },
    selectItem(o) {
      this.planItem = o;
      console.log('on planItem', o);
      this.params['planItem'] = this.planItem;
      this.params['machine'] = this.machine;
      this.params['icon'] = this.machineIcon(this.machine);
      console.log('props:', this.params);
      this.currentForm = operatorForm;
      this.refresh++;
    },
    backToList() {
      this.currentForm = plan;
      this.planItem = null;
      this.refresh++;
    },
    doRefresh() {
      this.loading = true;
      this.onRefresh();
      this.loading = false;
    },
    machineIcon(m) {
      console.log('machine_type:', m.type);
      switch (m.type) {
        case 'printer':
          return 'mdi-printer';
        case 'varnisher':
          return 'mdi-spray';
        case 'die cutter':
          return 'mdi-content-cut';
        case 'gluer':
          return 'mdi-vector-combine';
        case 'packager':
          return 'mdi-package-variant';
      }
      return 'mdi-tools';
    },
    getMachineCount(machine) {
      const index = this.machines.findIndex((m) => m.id === machine.id);
      return this.counts[index] || '...';
    }
  }
};
</script>

<style>
.vertical {
  flex-direction: column;
  background-color: white;
}
</style>
