var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{key:_vm.refresh,attrs:{"id":"op","fluid":""}},[(_vm.machine && !_vm.planItem)?_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-toolbar',[_c('v-btn',{staticClass:"hidden-xs-only",attrs:{"icon":""},on:{"click":_vm.changeMachine}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.machineIcon(_vm.machine))+" ")]),_vm._v(" "+_vm._s(_vm.machine.name)+" ")],1)],1)],1):_vm._e(),(_vm.machine && !_vm.planItem)?_c('v-card',{staticClass:"pa-4",attrs:{"elevation":"0"}},[_c('v-row',{attrs:{"align-self":"end"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-row',[_c('v-icon',{attrs:{"medium":""},on:{"click":() => {
            _vm.keybordVisible = !_vm.keybordVisible;
          }}},[_vm._v(" "+_vm._s(_vm.keybordVisible ? 'mdi-minus' : 'mdi-plus')+" ")]),_c('v-icon',{attrs:{"medium":""},on:{"click":() => {
            _vm.keybordVisible = !_vm.keybordVisible;
          }}},[_vm._v(" mdi-keyboard ")]),(_vm.keybordVisible)?_c('SimpleKeyboard',{key:_vm.keyboardEpoch,attrs:{"keyboard-class":"searchplan","input":_vm.search,"layout":_vm.layout},on:{"onChange":(input) => {
            _vm.search = input;
          }}}):_vm._e()],1)],1):_vm._e(),(!_vm.machine)?_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.groupedMachines),function(group){return _c('v-col',{key:group.type,staticClass:"d-flex flex-column align-start",attrs:{"cols":"auto"}},_vm._l((group.machines),function(machine){return _c('v-card',{key:machine.id,staticClass:"ma-2",attrs:{"color":"primary","dark":"","elevation":"0","max-width":"300","min-width":"300"},on:{"click":function($event){return _vm.onMachine(machine)}}},[_c('v-card-title',[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v(" "+_vm._s(_vm.machineIcon(machine))+" ")]),_c('span',{staticClass:"text-h3 font-weight-light"},[_vm._v(_vm._s(_vm.tType(machine.type)))])],1),_c('v-card-text',{staticClass:"text-h2 font-weight-bold"},[_vm._v(" "+_vm._s(machine.name)+" ")]),_c('v-card-actions',[_c('v-list-item',{staticClass:"grow"},[_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('v-chip',{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.getMachineCount(machine))+" ")])],1)],1)],1)],1)}),1)}),1):_vm._e(),(_vm.machine)?_c(_vm.currentForm,_vm._b({tag:"component",attrs:{"machine":_vm.machine,"epoch":_vm.epoch,"refresh":_vm.refresh,"search":_vm.search}},'component',_vm.params,false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }